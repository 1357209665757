import { UserEventType } from "./user-event-type";
import { User } from "./user";

export class UserEvent {
    public readonly user: User;
    public readonly type: UserEventType;


    public constructor(user: User, type: UserEventType) {
        this.user = user;
        this.type = type;
    }
}
