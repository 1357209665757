import { Callbacks } from '../callbacks';
import { ErrorLevel } from '../errorlevel';
import { IPlayer } from '../iplayer';
import { MediaErrorExt } from '../media-error-ext';
import { PlayPromiseInfo } from '../play-promise-info';
import { PlayerEvent, PlayModes } from '../player';
import { PlayerFactory } from '../player-factory';
import { QualityBoundaries } from '../quality-boundaries';
import { QualityLevel } from '../quality-level';
import { BrowserName, BrowserOS, isBrowserVersion } from '../utils';

import { SourceSet } from 'vchat-core';

const defaultQualityBoundaries: QualityBoundaries = {
    low: 0,
    medium: 250,
    good: 500,
};

const defaultWaitingTimeout = 3000;

const ABORT_ERROR = 'AbortError';
const NOT_ALLOWED_ERROR = 'NotAllowedError';
const NS_ERROR_NOT_AVAILABLE = 'NS_ERROR_NOT_AVAILABLE';

export interface HlsPlayerConfig {
    /** called when either a play() call was successful or the play() call failed;
     * this function receives a playInfo object (see [PlayPromiseInfo])
     * */
    playLogger?: (playInfo: PlayPromiseInfo) => void;
    /** a path to a poster to be set on the video element */
    poster?: string;
    /** an object with attributes low, medium, good indicating which quality level is reached at what resolution (the value to each key is the minimal video width) */
    qualityBoundaries?: QualityBoundaries;
    /** in milliseconds, time until a new play() call will be triggered when the stream does not recover in time */
    recoverTimeout?: number;
    /** this function retrieves a certain reloadFunction which provides an additional play button on Apple devices in the rare case that the stream is active but the video image is stuck */
    reloadCallback?: (reloadFunc: (e) => void) => void;
    /** when set to true a separate canvas is used instead of the video elements own poster attribute in order to provide a valid image whenever the video element is stuck */
    useCanvasPoster?: boolean;
    /** called when the video elements throws a pause event or when the automatic playback failed; receives a function as first parameter; this function can then be used to trigger a new play() event on the video element */
    userInteractionCallback?: (userInteractionFunc: (e) => void) => void;
    /** timeout in milliseconds (starting when the video element throws a waiting event) until the player is torn down and the vchat-player tries the next one */
    waitingTimeout?: number;
}

/**
A player for HLS streams using the native HLS implementation of the browser (currently available on Apple devices and in Chrome on Android).

* supported format:  hls
* name:              HLS_NATIVE
 */
export class HlsPlayerFactory implements PlayerFactory {
    public readonly format = 'hls';

    private config: HlsPlayerConfig;

    constructor(config?: HlsPlayerConfig) {
        this.config = config || {};
    }

    public isSupported(): boolean {
        /*
         * currently limited to browsers with support for autoplay
         * - Safari >= 10 (muted autoplay)
         * - Chrome >= 53 (on iOS / Android)
         */
        return (
            isBrowserVersion(BrowserName.SAFARI, 10) ||
            isBrowserVersion(BrowserName.CHROME, 53, BrowserOS.IOS) ||
            isBrowserVersion(BrowserName.CHROME, 53, BrowserOS.ANDROID)
        );
    }

    public async create(callbacks: Callbacks, video: HTMLVideoElement): Promise<IPlayer> {
        let supportsPlayPromise = false;
        const dummyVideo = document.createElement('video');
        const promise = dummyVideo.play();

        if (promise) {
            supportsPlayPromise = true;
            promise.catch(() => {
                /** noop */
            });
        }

        let playMode = supportsPlayPromise
            ? PlayModes.PROMISE_PLAY_UNMUTED
            : PlayModes.AUTO_PLAY_UNMUTED;

        if (!video) {
            video = document.createElement('video');
            video.volume = 1;
            video.muted = false;
        }

        video.crossOrigin = 'anonymous';
        video.controls = false;
        video.style.width = '100%';
        video.style.height = '100%';

        if (!supportsPlayPromise) {
            video.autoplay = true;
        }

        if (this.config.poster) {
            video.poster = this.config.poster;
        }

        video.setAttribute('playsinline', 'true');

        let canvas: HTMLCanvasElement = undefined;

        if (this.config.useCanvasPoster || this.config.useCanvasPoster === undefined) {
            canvas = document.createElement('canvas');
            canvas.style.position = 'absolute';
            canvas.style.left = '-100000px';
            canvas.style.top = '0';
        }

        let quality: QualityLevel = 'low';
        let currentSource = '';
        let currentVideoWidth = 0;

        let sources = [];

        let hlsAbortErrorRetry = false;
        let playTriggeredByUserInteraction = false;

        let isPlaying = false;
        let isStopped = false;

        let playerVolume = video.volume;
        let lastTimeUpdate = 0;
        let preFirstFrameCounter = 0;

        let canvasHideInterval = undefined;
        let heartBeatInterval = undefined;

        let autoPlayTimeout: ReturnType<typeof setTimeout> = undefined;
        let waitingTimeout: ReturnType<typeof setTimeout> = undefined;

        let controlsShown = false;

        const sendPlayInfo = (): void => {
            callbacks.onPlayInfo({
                width: video.videoWidth,
                height: video.videoHeight,
                quality: quality,
                volume: video.volume,
                paused: video.paused,
                name: player.name,
                source: currentSource,
            });
        };

        const setQuality = (newQuality: QualityLevel): void => {
            quality = newQuality;
            sendPlayInfo();
        };

        const videoResolutionChange = (videoWidth: number): void => {
            if (!isStopped) {
                let newQuality: QualityLevel = 'low';
                const boundaries = this.config.qualityBoundaries || defaultQualityBoundaries;

                if (boundaries.good && videoWidth >= boundaries.good) {
                    newQuality = 'good';
                } else if (boundaries.medium && videoWidth >= boundaries.medium) {
                    newQuality = 'medium';
                }

                if (newQuality !== quality || videoWidth !== currentVideoWidth) {
                    setQuality(newQuality);
                }

                currentVideoWidth = videoWidth;
            }
        };

        const onCanPlay = (): void => {
            if (!supportsPlayPromise) {
                autoPlayTimeout = setTimeout(() => {
                    if (!playTriggeredByUserInteraction) {
                        play(true);
                    } else {
                        logPlay({
                            name: 'AutoPlayError',
                            message: 'AutoPlay failed',
                        });

                        onError();
                    }
                }, 500);
            }
        };

        const onEnded = (): void => {
            callbacks.onError('ended');
        };

        const onError = (): void => {
            // ignore errors after stream is stopped
            if (!isStopped) {
                let errorCode = 0;
                let errorMessage = '';

                if (video && video.error) {
                    const errorExt: MediaErrorExt = video.error as MediaErrorExt;

                    errorCode = errorExt.code;
                    errorMessage = errorExt.message;
                }

                if (
                    errorCode === MediaError.MEDIA_ERR_DECODE ||
                    errorCode === MediaError.MEDIA_ERR_NETWORK
                ) {
                    callbacks.onPlayError(ErrorLevel.Info, {
                        code: errorCode,
                        message: errorMessage,
                    });
                    resumePlay();
                } else {
                    callbacks.onError({
                        code: errorCode,
                        message: errorMessage,
                    });
                }
            }
        };

        const onHeartBeat = (): void => {
            let recoverTimeout = this.config.recoverTimeout || 2000;

            if (preFirstFrameCounter > 0) {
                recoverTimeout *= 3;
            }

            if (
                isPlaying &&
                lastTimeUpdate &&
                window.performance.now() - recoverTimeout > lastTimeUpdate
            ) {
                resumePlay();
            }
        };

        const onPause = (): void => {
            if (!isStopped) {
                callbacks.onSendMetrics(PlayerEvent.Pause);

                if (isPlaying) {
                    isPlaying = false;
                    // TODO: startStream again?

                    // TODO: pause can be triggered by user (e.g. via Android Phone: notification bar -> pause button @ stream -> pauses stream and can only be restarted from the notification bar)
                    if (this.config.userInteractionCallback) {
                        this.config.userInteractionCallback(resumeByButton);
                    }
                }
            }
        };

        const onPlayError = (error: string | DOMException): void => {
            if (video && video.error) {
                onError();
            } else if (typeof error === 'string') {
                callbacks.onPlayError(ErrorLevel.Info, { message: error });
            } else if (typeof error === 'object') {
                callbacks.onPlayError(ErrorLevel.Info, {
                    name: error.name,
                    message: error.message,
                });
            } else {
                callbacks.onError({ code: '1337', message: 'Unknown error' });
            }
        };

        const onPlaying = (): void => {
            isPlaying = true;
            // isInitialPlayProcessed = true

            if (autoPlayTimeout) {
                clearTimeout(autoPlayTimeout);
                autoPlayTimeout = undefined;
            }

            callbacks.onSendMetrics(PlayerEvent.Playing);
            sendPlayInfo();

            // move the canvas
            if (canvasHideInterval) {
                clearInterval(canvasHideInterval);
            }

            if (canvas) {
                canvasHideInterval = setInterval(canvasHide, 25);
            }

            if (waitingTimeout) {
                window.clearTimeout(waitingTimeout);
                waitingTimeout = undefined;
            }

            if (controlsShown) {
                video.controls = false;
                controlsShown = false;
            }
        };

        const onTimeUpdate = (): void => {
            if (isPlaying && video.videoWidth > 0) {
                videoResolutionChange(video.videoWidth);
                canvasUpdate();

                if (waitingTimeout) {
                    clearTimeout(waitingTimeout);
                    waitingTimeout = undefined;
                }

                if (preFirstFrameCounter === 2) {
                    preFirstFrameCounter = 0;
                } else if (preFirstFrameCounter > 0) {
                    preFirstFrameCounter++;
                }
            }

            lastTimeUpdate = window.performance.now();
        };

        const onWaiting = (): void => {
            if (!isStopped) {
                callbacks.onSendMetrics(PlayerEvent.Waiting);

                if (waitingTimeout) {
                    clearTimeout(waitingTimeout);
                }

                waitingTimeout = setTimeout(() => {
                    setQuality('low');
                }, this.config.waitingTimeout || defaultWaitingTimeout);
            }
        };

        const onWindowResize = (): void => {
            canvasUpdate();
        };

        const canvasUpdate = (): void => {
            if (canvas) {
                if (video.videoHeight > 0) {
                    canvas.height = video.clientHeight;
                    canvas.width = video.clientWidth;
                }

                window.requestAnimationFrame(canvasUpdateDo);
            }
        };

        const canvasUpdateDo = (): void => {
            if (canvas && video.readyState >= 2) {
                try {
                    // do not write directly to video's poster because it will be overwritten by hls.loadSource()
                    canvas
                        .getContext('2d')
                        .drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
                } catch (error) {
                    if (error.name === NS_ERROR_NOT_AVAILABLE) {
                        // Wait a bit before trying again
                        setTimeout(canvasUpdateDo, 1);
                    } else {
                        throw error;
                    }
                }
            }
            // TODO: directly write to poster? not working in Safari 10 (no cross-origin setting on video), only on readyState >= 2
            // video.poster = canvas.toDataURL('image/png')
        };

        const canvasHide = (): void => {
            if (canvas && video.videoWidth) {
                canvas.style.left = '-100000px';

                clearInterval(canvasHideInterval);
                canvasHideInterval = undefined;
            }
        };

        const resumePlay = (fromButton = false, reload = false): void => {
            if (canvas) {
                canvas.style.left = '0';
            }

            if (waitingTimeout) {
                clearTimeout(waitingTimeout);
            }

            waitingTimeout = setTimeout(() => {
                setQuality('low');
            }, this.config.waitingTimeout || defaultWaitingTimeout);

            isPlaying = false;

            // timeout is necessary to give canvas enough time to be put in place
            setTimeout(() => {
                video.load();

                play(false, fromButton);
            }, 100);

            if (reload) {
                callbacks.onSendMetrics(PlayerEvent.Reload);
            } else {
                callbacks.onSendMetrics(PlayerEvent.Load);
            }
        };

        video.addEventListener('canplay', onCanPlay, true);
        video.addEventListener('ended', onEnded, true);
        video.addEventListener('error', onError, true);
        video.addEventListener('pause', onPause, true);
        video.addEventListener('playing', onPlaying, true);
        video.addEventListener('timeupdate', onTimeUpdate, true);
        video.addEventListener('waiting', onWaiting, true);

        window.addEventListener('resize', onWindowResize);

        heartBeatInterval = setInterval(onHeartBeat, 250);

        const logPlay = (error: Error = undefined): void => {
            const videoData = {
                muted: video.muted,
                paused: video.paused,
                volume: video.volume,
            };

            const playerInfo: PlayPromiseInfo = {
                playMode: playMode,
                success: !error,
                videoElement: videoData,
                volume: playerVolume,
            };

            if (error) {
                playerInfo.error = error.name;
                playerInfo.errorMsg = error.message;
            }

            callbacks.onSendMetrics(PlayerEvent.Play, playerInfo);

            if (this.config.playLogger) {
                this.config.playLogger(playerInfo);
            }
        };

        const play = (mute = false, button = false): void => {
            isStopped = false;
            preFirstFrameCounter = 1;

            if (supportsPlayPromise) {
                video
                    .play()
                    .then(() => {
                        logPlay();
                    })
                    .catch((error) => {
                        logPlay(error);
                        if (error.name === NOT_ALLOWED_ERROR || error.name === ABORT_ERROR) {
                            if (button) {
                                onPlayError(error);
                            } else if (error.name == ABORT_ERROR && !hlsAbortErrorRetry) {
                                // retry once to cover occurring load() interrupts play()
                                hlsAbortErrorRetry = true;
                                setTimeout(() => {
                                    play();
                                }, 50);
                            } else if (!video.muted) {
                                playerVolume = 0;

                                video.muted = true;
                                video.volume = 0;

                                playMode = PlayModes.PROMISE_PLAY_MUTED;

                                callbacks.onVolumeChange(0);

                                play();
                            } else {
                                if (this.config.userInteractionCallback) {
                                    playMode = PlayModes.PROMISE_PLAY_BUTTON;

                                    this.config.userInteractionCallback(playByButton);

                                    if (error.name === NOT_ALLOWED_ERROR) {
                                        onPlayError(NOT_ALLOWED_ERROR + ': Try controls play');
                                        video.controls = true;
                                        controlsShown = true;
                                    }
                                } else {
                                    onPlayError('No userInteractionCallback set');
                                }
                            }
                        } else {
                            onPlayError(error);
                        }
                    });
            } else {
                if (mute) {
                    logPlay({
                        name: 'AutoPlayError',
                        message: 'AutoPlay failed',
                    });

                    if (!video.muted) {
                        playerVolume = 0;

                        video.muted = true;
                        video.volume = 0;

                        playMode = PlayModes.AUTO_PLAY_MUTED;

                        callbacks.onVolumeChange(0);

                        video.play();
                    } else {
                        if (this.config.userInteractionCallback) {
                            playMode = PlayModes.AUTO_PLAY_BUTTON;

                            this.config.userInteractionCallback(playByButton);
                        } else {
                            onPlayError('No userInteractionCallback set');
                        }
                    }
                } else {
                    video.play();
                }
            }
        };

        const playByButton = (e): void => {
            if (e && e.cancelable) {
                e.preventDefault();
                e.stopPropagation();
            }

            playerVolume = 1;

            video.muted = false;
            video.volume = 1;

            callbacks.onVolumeChange(1);

            play(false, true);

            playTriggeredByUserInteraction = true;
        };

        const resumeByButton = (e): void => {
            if (e && e.cancelable) {
                e.preventDefault();
                e.stopPropagation();
            }

            resumePlay(true);
        };

        const playNext = (): void => {
            const source = sources.shift();
            if (source) {
                removeSources(video);

                callbacks.onPlayStart(source);

                currentSource = source.stream;

                sendPlayInfo();

                const srcElement = document.createElement('source');
                srcElement.src = currentSource;
                srcElement.type = 'video/mp4';

                video.append(srcElement);

                play();
            } else {
                callbacks.onError('source');
            }
        };

        const player: IPlayer = {
            el: [video, canvas],
            name: 'HLS_NATIVE',
            play: (sourceSet: SourceSet) => {
                sources = sourceSet.hls ? sourceSet.hls.slice() : [];
                playNext();
            },

            stop: () => {
                isPlaying = false;
                isStopped = true;

                // move canvas into position as a poster-replacement (Safari 10 / 11 does not support crossOrigin setting on video hence the canvas cannot export its data to the video element's poster attribute)
                if (canvas) {
                    canvas.style.left = '0';
                }

                // special clean up for HLS on iOS to force stop load
                video.pause();
                removeSources(video);
                video.removeAttribute('src');
                video.preload = 'none';

                try {
                    video.load();
                } catch (error) {
                    // do nothing
                }

                callbacks.onPlayStop();

                if (waitingTimeout) {
                    clearTimeout(waitingTimeout);
                    waitingTimeout = undefined;
                }
            },

            destroy: () => {
                // do clean up since video element will be reused
                removeSources(video);
                video.removeEventListener('ended', onEnded, true);
                video.removeEventListener('error', onError, true);
                video.removeEventListener('canplay', onCanPlay, true);
                video.removeEventListener('pause', onPause, true);
                video.removeEventListener('playing', onPlaying, true);
                video.removeEventListener('timeupdate', onTimeUpdate, true);
                video.removeEventListener('waiting', onWaiting, true);

                window.removeEventListener('resize', onWindowResize);

                clearInterval(heartBeatInterval);

                if (canvasHideInterval) {
                    clearInterval(canvasHideInterval);
                }

                player.stop();
                // .destroy()

                // clean up video element
                video.crossOrigin = undefined;
                video.controls = false;
                video.style.width = undefined;
                video.style.height = undefined;
                video.removeAttribute('autoplay');

                return video;
            },

            setVolume: (value) => {
                playerVolume = value;

                video.volume = value;
                video.muted = !value;
            },
        };

        if (this.config.reloadCallback) {
            this.config.reloadCallback((e): void => {
                if (e && e.cancelable) {
                    e.preventDefault();
                    e.stopPropagation();
                }

                resumePlay(true, true);
            });
        }

        return player;
    }
}

function removeSources(video: HTMLVideoElement): void {
    for (let c = video.children.length - 1; c >= 0; c--) {
        // eslint-disable-next-line unicorn/prefer-node-remove
        video.removeChild(video.children[c]);
    }
}
