import { LogEntry } from './log-entry';
import { Logger } from './logger';

export class LogCollector implements Logger {
    public static readonly LOG = 10;
    public static readonly WARN = 20;
    public static readonly ERROR = 30;
    private logEntries: LogEntry[] = [];
    private logger: Logger;
    constructor(l?: Logger) {
        this.logger = l;
    }

    public clear(): void {
        this.logEntries = [];
    }

    get logs(): LogEntry[] {
        return this.logEntries;
    }

    public log(message?: any, ...params: any[]): void {
        this.push(LogCollector.LOG, message, params);
    }

    public warn(message?: any, ...params: any[]): void {
        this.push(LogCollector.WARN, message, params);
    }

    public error(message?: any, ...params: any[]): void {
        this.push(LogCollector.ERROR, message, params);
    }

    public exportToStr(minLevel = 0, entries = 0): string {
        let out = '';
        const len = this.logEntries.length;
        for (let i = entries === 0 ? 0 : Math.max(len - entries, 0); i < len; i++) {
            const entry = this.logEntries[i];
            if (entry.level < minLevel) {
                continue;
            }

            const level = LogCollector.getLogLevelAsString(entry.level);
            const date = LogCollector.GetLogEventDateAsString(new Date(entry.time));

            out +=
                date +
                ' ' +
                level +
                ' ' +
                entry.message +
                (entry.params ? ' ' + JSON.stringify(entry.params) : '') +
                '\n';
        }
        return out;
    }

    private static GetLogEventDateAsString(d: Date): string {
        return d.toTimeString();
    }

    private static getLogLevelAsString(level: number): string {
        if (level >= LogCollector.ERROR) {
            return 'ERROR';
        } else if (level >= LogCollector.WARN) {
            return 'WARN';
        } else {
            return 'INFO';
        }
    }

    private static getLogMethodByLevel(
        logger: Logger,
        level: number
    ): (message?: any, ...params: any[]) => void {
        if (level >= LogCollector.ERROR) {
            return logger.error;
        } else if (level >= LogCollector.WARN) {
            return logger.warn;
        } else {
            return logger.log;
        }
    }

    private push(level: number, message: any, params: any[]): void {
        this.logEntries.push({ level, time: Date.now(), message, params });
        if (this.logger) {
            const method = LogCollector.getLogMethodByLevel(this.logger, level);
            if (params) {
                const p = [message].concat(params);
                try {
                    // TODO edge-mobile doesn't like it
                    method(...p);
                } catch {
                    //
                }
            } else {
                method(message);
            }
        }
    }
}
