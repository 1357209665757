import { IPlayer } from '../../iplayer';
import { SourceSet, H5LiveSource } from 'vchat-core';
import { Callbacks } from '../../callbacks';
import { NanoPlayerEvent, SourceEntry, NanoPlayerConfig } from './nano-player-types';
import './nanoplayer.4.3.2.min.js';

export class H5LivePlayer implements IPlayer {
    el: HTMLElement[];
    public readonly name = 'H5Live';
    public readonly canPublish? = false;

    private _playButton: HTMLElement = this.createPlayButton();
    private _player: NanoPlayer;
    private _callbacks: Callbacks;
    private _playerDiv: HTMLDivElement;
    private _currentSource?: H5LiveSource;
    private _volume?: number;
    private _isPlayerMuted: boolean;
    private _playerVolume: number;
    private _paused = false;
    private _stopRequested = false;

    private get playerVid(): HTMLVideoElement {
        return (
            this._playerDiv?.querySelector('video') ||
            this._playerDiv?.querySelector('iframe').contentDocument.querySelector('video')
        );
    }

    public constructor(callbacks: Callbacks, playerElement: HTMLDivElement) {
        this._callbacks = callbacks;
        this._playerDiv = playerElement;
        this.el = [playerElement];
    }

    public async play(sourceSet: SourceSet): Promise<void> {
        const h5liveSource = sourceSet.h5live[0];
        this._currentSource = h5liveSource;

        if (!this._player) {
            this._player = await this._createPlayer(h5liveSource);
        } else {
            const source = {
                entries: [this._createEntry(h5liveSource)],

                startIndex: 0,
            };

            await this._player.updateSource(source);
        }
    }

    public stop(): void {
        this._stopRequested = true;
        this._player.pause();
    }

    public destroy(): HTMLVideoElement {
        this._currentSource = undefined;
        this._player?.destroy();
        return null;
    }

    public setVolume(volume: number): void {
        if (this._player) {
            this._player.setVolume(volume);

            if (volume > 0) {
                this._player.unmute();
            } else {
                this._player.mute();
            }
        } else {
            // store this for when the player is started
            this._volume = volume;
        }
    }

    private _shouldPlayerStartMuted(): boolean {
        return this._volume === 0;
    }

    private _createEntry(h5liveSource: H5LiveSource): SourceEntry {
        const entry: SourceEntry = {
            h5live: {
                server: h5liveSource.server,
            },
        };

        if (h5liveSource.rtmp) {
            entry.h5live.rtmp = h5liveSource.rtmp;
        }

        if (h5liveSource.bintu) {
            entry.bintu = h5liveSource.bintu;
        }

        if (h5liveSource.token) {
            entry.h5live.token = h5liveSource.token;
        }

        return entry;
    }

    private _onPlay(): void {
        this._paused = false;
        this._callbacks.onPlayStart(this._currentSource.rtmp.streamname);
        this.sendPlayInfo();
    }

    private sendPlayInfo(): void {
        const v = this.playerVid;

        if (this._player) {
            this._callbacks.onPlayInfo({
                width: v.clientWidth,
                height: v.clientHeight,
                quality: 'medium',
                paused: this._paused,
                volume: this._volume,
                name: this.name,
                source: this._currentSource?.rtmp.streamname,
            });
        }
    }

    private _onVolumeChange(event: NanoPlayerEvent): void {
        this._playerVolume = event.data.volume;

        this._updateCombinedVolume();
    }

    private _updateCombinedVolume(): void {
        const newCombinedVolume = this._isPlayerMuted ? 0 : this._playerVolume;

        if (this._volume !== newCombinedVolume) {
            this._volume = newCombinedVolume;
            this._callbacks.onVolumeChange(this._volume);
            this.sendPlayInfo();
        }
    }

    private _onWarning(event: NanoPlayerEvent): void {
        this._callbacks.onSendMetrics('h5live_warning', event.data);
    }

    private _onError(event: NanoPlayerEvent): void {
        if (isUserGestureIsRequiredError(event)) {
            this._onWarning(event);
            this._displayPlayButtonForUserGesture();
        } else if (isPlaybackSuspendedError(event)) {
            this._onWarning(event);
            this._waitForPageActivation();
        } else {
            this._callbacks.onError(event.data);
        }
    }

    private _handlePageIsVisibleAgain(): void {
        this._player?.play();
        removePageVisibilityChangedEventListener(this._handlePageIsVisibleAgain);
    }

    private _waitForPageActivation(): void {
        if (isPageVisibilityApiSupported()) {
            addPageVisibilityChangedEventListener(this._handlePageIsVisibleAgain.bind(this), false);
        } else {
            this._displayPlayButtonForUserGesture();
        }
    }

    private _displayPlayButtonForUserGesture(): void {
        this._playerDiv.append(this._playButton);
    }

    private playByButton(): void {
        this._playButton.remove();
        this._player.play();
    }

    private _onPause(): void {
        this._paused = true;
        if (this._stopRequested) {
            this._callbacks.onPlayStop();
            this._stopRequested = false;
        }

        this.sendPlayInfo();
    }

    private _onStats(): void {
        // this._callbacks.onSendMetrics('h5live_stats', event.data.stats);
    }

    private _onMute(): void {
        this._isPlayerMuted = true;
        this._updateCombinedVolume();
    }

    private _onUnmute(): void {
        this._isPlayerMuted = false;
        this._updateCombinedVolume();
    }

    private async _createPlayer(h5liveSource: H5LiveSource): Promise<NanoPlayer> {
        // eslint-disable-next-line no-undef
        const player = new NanoPlayer(this._playerDiv.id);

        const config: NanoPlayerConfig = {
            source: {
                entries: [this._createEntry(h5liveSource)],
                startIndex: 0,
                options: {
                    switch: {
                        fastStart: true,
                    },
                },
            },
            events: {
                onPlay: this._onPlay.bind(this),
                onVolumeChange: this._onVolumeChange.bind(this),
                onWarning: this._onWarning.bind(this),
                onError: this._onError.bind(this),
                onPause: this._onPause.bind(this),
                onStats: this._onStats.bind(this),
                onMute: this._onMute.bind(this),
                onUnmute: this._onUnmute.bind(this),
            },
            playback: {
                autoplay: true,
                automute: true,
                muted: this._shouldPlayerStartMuted(),
                // videoId: this._playerVid.id
            },
            style: {
                width: 'auto',
                height: 'auto',
                controls: false,
                interactive: false,
                view: false,
            },
            metrics: {
                accountId: h5liveSource.metrics?.id, // do not change
                accountKey: h5liveSource.metrics?.key, // do not change
                userId: h5liveSource.metrics?.dataId, // value can be changed per viewer
                // eventId: 'event1',          // value can be changed per event
                statsInterval: 10, // statistics interval in seconds
            },
        };

        await player.setup(config);

        if (this._volume !== undefined) {
            player.setVolume(this._volume);
        }

        const v = this.playerVid;

        // this needs to be reset, as player.setup will delete the previous set style
        v.style.width = '100%';
        v.style.height = '100%';

        return player;
    }

    private createPlayButton(): HTMLElement {
        const playButton = document.createElement('div');

        playButton.id = 'playButton';
        playButton.addEventListener('click', this.playByButton.bind(this));
        playButton.innerHTML =
            '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve"> <style type="text/css">.st0{fill:#FFFFFF;}</style><path id="play-full" class="st0" d="M2,3C0.9,3,0,3.9,0,5v20c0,1.1,0.9,2,2,2h26c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2H2z M21.5,15.8l-8.4,5.6c-0.5,0.3-1.1,0.2-1.4-0.3c-0.1-0.2-0.2-0.4-0.2-0.6V9.4c0-0.6,0.4-1,1-1c0.2,0,0.4,0.1,0.6,0.2l8.4,5.6c0.5,0.3,0.6,0.9,0.3,1.4C21.7,15.7,21.6,15.8,21.5,15.8z"/> </svg>';

        playButton.style.position = 'absolute';
        playButton.style.top = '50%';
        playButton.style.left = '50%';
        playButton.style.transform = 'translate(-50%, -50%)';
        playButton.style['-webkit-transform'] = 'translate(-50%, -50%)';
        playButton.style.width = '125px';
        playButton.style.height = '125px';

        return playButton;
    }
}

function isUserGestureIsRequiredError(event: NanoPlayerEvent): boolean {
    return event.data.code === 1005;
}

function isPlaybackSuspendedError(event: NanoPlayerEvent): boolean {
    return event.data.code === 1007;
}

function isPageVisibilityApiSupported(): boolean {
    return (
        document.hidden !== undefined ||
        (document as any).msHidden !== undefined ||
        (document as any).webkitHidden !== undefined
    );
}

// function isPageHidden(): boolean {
//     if (document.hidden !== undefined) {
//         return document.hidden;
//     } else {
//         const documentAsAny = document as any;

//         if (documentAsAny.msHidden !== undefined) {
//             return documentAsAny.msHidden;
//         } else if (documentAsAny.webkitHidden !== undefined) {
//             return documentAsAny.webkitHidden;
//         }
//     }

//     throw new Error('Page Visibility not supported');
// }

function getPageVisibilityChangedEventName(): string {
    if (document.hidden !== undefined) {
        return 'visibilitychange';
    } else {
        const documentAsAny = document as any;

        if (documentAsAny.msHidden !== undefined) {
            return 'msvisibilitychange';
        } else if (documentAsAny.webkitHidden !== undefined) {
            return 'webkitvisibilitychange';
        }
    }

    throw new Error('Page Visibility not supported');
}

function addPageVisibilityChangedEventListener(
    visibilityChangedListener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
): void {
    const pageVisibilityChangedEventName = getPageVisibilityChangedEventName();

    document.addEventListener(pageVisibilityChangedEventName, visibilityChangedListener, options);
}

function removePageVisibilityChangedEventListener(
    visibilityChangedListener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
): void {
    const pageVisibilityChangedEventName = getPageVisibilityChangedEventName();

    document.removeEventListener(
        pageVisibilityChangedEventName,
        visibilityChangedListener,
        options
    );
}
