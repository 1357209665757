import * as platform from 'platform';

export const BrowserName = {
    ALL: 'All',
    CHROME: 'Chrome',
    FIREFOX: 'Firefox',
    SAFARI: 'Safari',
    IE: 'IE',
    EDGE: 'Microsoft Edge',
};

export const BrowserOS = {
    ANDROID: 'Android',
    IOS: 'iOS',
};

export function createElement<K extends keyof HTMLElementTagNameMap>(
    tagName: K,
    style?: CSSStyleDeclaration | object
): HTMLElementTagNameMap[K] {
    const el = document.createElement(tagName);
    if (style) {
        for (const n in style) {
            el.style[n] = style[n];
        }
    }
    return el;
}

export function isBrowserVersion(
    name: string,
    version?: number,
    os?: string | null,
    versionTo?: number
): boolean {
    os = os || null;
    version = version || 0;
    versionTo = versionTo || 0;

    if (os) {
        os = os.toLowerCase();
    }

    if (name === BrowserName.ALL) {
        name = null;
    } else {
        name = name.toLowerCase();
    }

    let platformName = null;
    let platformOS = null;

    // platform.name could be null (e.g. for T-Online Browser)
    if (name && platform.name) {
        platformName = platform.name.toLowerCase();

        if (platformName.length > name.length) {
            platformName = platformName.slice(0, name.length);
        }
    }

    if (os && platform.os && platform.os.family) {
        platformOS = platform.os.family.toLowerCase();

        if (platformOS.length > os.length) {
            platformOS = platformOS.slice(0, os.length);
        }
    }

    const isOS = os === null || platformOS === os;
    const isName = name === null || platformName === name;
    const isVersion = Number.parseInt(platform.version, 10) >= version;
    const isVersionTo = versionTo === 0 || Number.parseInt(platform.version, 10) < versionTo;

    return isOS && isName && isVersion && isVersionTo;
}
