import { IPlayer } from '../../iplayer';
import { SourceSet } from 'vchat-core';
import { QualityLevel } from '../../quality-level';

// import 'webrtc-adapter';

export class WebRTCPlayer implements IPlayer {
    public el: HTMLElement[];
    public readonly name = 'WebRTC';
    public readonly canPublish? = false;

    private _peerConnection: RTCPeerConnection;
    private _closingConnection: boolean;
    private _videoElement: HTMLVideoElement;
    private _socket: WebSocket;

    constructor(video: HTMLVideoElement) {
        this._videoElement = video;
        video.id = 'webRTCVideo';
        this.el = [video];
    }

    public play(sourceSet: SourceSet): void {
        const webRtcSource = sourceSet.webrtc[0];
        this._openSignalingConnection(webRtcSource.stream);
    }

    public stop(): void {
        this._closeDataConnection();
        this._closeSignalingConnection();
    }

    public destroy(): HTMLVideoElement {
        this._closeDataConnection();
        this._closeSignalingConnection();
        return this._videoElement;
    }

    public setVolume(volume: number): void {
        this._videoElement.volume = volume;
    }

    public close(): void {
        // this.closing = true;
        // this.noop.active = false;
        if (this._socket) {
            this._socket.close();
        }
    }

    private _closeSignalingConnection(): void {
        if (this._socket) {
            this._socket.close();
            this._socket = undefined;
        }
    }

    private _openDataConnection(): void {
        const peerConnection = new RTCPeerConnection({
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
        });

        peerConnection.ontrack = this._attachStreamToVideo.bind(this);
        peerConnection.onconnectionstatechange = this._peerConnectionStateChanged.bind(this);
        // peerConnection.onicegatheringstatechange = this._iceGatheringStateChanged.bind(this);
        peerConnection.onicecandidate = this._iceCandidate.bind(this);
        // peerConnection.onicecandidateerror = this._iceCandidateError.bind(this);
        // peerConnection.onnegotiationneeded = this._negotiationNeeded.bind(this);
        // peerConnection.onsignalingstatechange = this._signalingStateChanged.bind(this);
        // peerConnection.onstatsended = this._statsEnded.bind(this);

        this._peerConnection = peerConnection;
    }

    private _attachStreamToVideo(event: RTCTrackEvent): void {
        this._videoElement.id = 'Playing_web_RTC';
        this._videoElement.autoplay = true;

        this._videoElement.srcObject = event.streams[0];
    }

    private _peerConnectionStateChanged(_ev: Event): void {
        // NOOP
    }

    private _iceCandidate(ev: RTCPeerConnectionIceEvent): void {
        if (ev.candidate != null) {
            this._sendWebRtcIceCandidate(ev.candidate);
        } else {
            this._sendWebRtcNoMoreCandidates();
        }
    }

    private _sendWebRtcIceCandidate(candidate: RTCIceCandidate): void {
        this._dispatchCommand('CMDS_WEBRTC_ICE_CANDIDATE', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            ice_candidate: candidate.candidate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            ice_candidate_sdpMLineIndex: candidate.sdpMLineIndex,
            // eslint-disable-next-line @typescript-eslint/camelcase
            ice_candidate_sdpMid: candidate.sdpMid,
        });
    }

    private _changeStreamQuality(quality: QualityLevel): void {
        let substream = 0;

        switch (quality) {
            case 'low':
                substream = 2;
                break;
            case 'medium':
                substream = 1;
                break;
            case 'good':
                substream = 0;
                break;
        }

        this._dispatchCommand('CMDS_WEBRTC_CHANGE_QUALITY', {
            substream: substream,
            // temporal: 1
        });
    }

    private _sendWebRtcNoMoreCandidates(): void {
        this._dispatchCommand('CMDS_WEBRTC_ICE_NO_MORE_CANDIDATES', {});
    }

    private _openSignalingConnection(signalingUrl: string): void {
        const socket = new WebSocket(signalingUrl);
        socket.onopen = (): void => {
            this._onopen();
        };
        socket.onmessage = this._onmessage.bind(this);
        socket.onerror = this._onerror.bind(this);
        socket.onclose = this._onclose.bind(this);
        this._socket = socket;
    }

    private _onopen(): void {
        this._openDataConnection();
        this._requestChat();
    }

    private _requestChat(): void {
        this._dispatchCommand('CMDS_WEBRTC_START');
    }

    private _dispatchCommand(action: string, params: any = {}): void {
        const jsonData = JSON.stringify({ action, params });
        this._socket.send(jsonData);
    }

    private _onmessage(event: MessageEvent): void {
        const data = JSON.parse(event.data);
        switch (data.action) {
            case 'CMDC_WEBRTC_OFFER':
                this._handleWebRtcOffer(data.params.jsepSdp);
                break;
            case 'CMDP_NOOP':
                // this is a keep alive event,do nothing
                break;
            default:
                break;
        }
    }

    private async _handleWebRtcOffer(sdp: string): Promise<void> {
        await this._peerConnection.setRemoteDescription(
            new RTCSessionDescription({ type: 'offer', sdp })
        );
        const answer = await this._peerConnection.createAnswer();
        await this._peerConnection.setLocalDescription(new RTCSessionDescription(answer));
        const answerForServer = this._peerConnection.localDescription;
        this._sendWebRtcAnswer(answerForServer);
    }

    private _sendWebRtcAnswer(answer: RTCSessionDescription): void {
        this._dispatchCommand('CMDS_WEBRTC_JSEPANSWER', {
            jsepType: answer.type,
            jsepSdp: answer.sdp,
        });
    }

    private _onerror(): void {
        this._peerConnection.close();
    }

    private _onclose(): void {
        this._closeDataConnection();
    }

    private _closeDataConnection(): void {
        if (this._peerConnection) {
            this._peerConnection.close();
            this._peerConnection = null;
        }
    }
}
