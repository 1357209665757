
export interface User {
    userName: string;
}

export function isUser(arg: any): arg is User {
    return arg
        && arg.userName
        && typeof(arg.userName) == 'string';
}


